import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "loader"
    }}>{`Loader`}</h1>
    <p>{`Indicate the loading state of a component or page, built entirely with HTML and CSS.`}</p>
    <p>To see all the colors and their variations go to the <a className='pink' href='/styleguide'>Styleguide page</a></p>
    <h2 {...{
      "id": "default--spinner"
    }}>{`Default | Spinner`}</h2>
    <p>{`To change color just change the `}<inlineCode parentName="p">{`border`}</inlineCode>{` classes and to change size use `}<inlineCode parentName="p">{`small`}</inlineCode>{`, `}<inlineCode parentName="p">{`big`}</inlineCode>{` or `}<inlineCode parentName="p">{`bigger`}</inlineCode>{`. Without size classes the size is default `}<inlineCode parentName="p">{`2rem`}</inlineCode>{`.`}</p>
    <div className="loader small mb-3 display-block" role="status">
  <span className="visually-hidden">Loading...</span>
    </div>
    <div className="loader mb-3 display-block" role="status">
  <span className="visually-hidden">Loading...</span>
    </div>
    <div className="loader big border-lilac mb-3 display-block" role="status">
  <span className="visually-hidden">Loading...</span>
    </div>
    <div className="loader bigger border-lilac mb-3 display-block" role="status">
  <span className="visually-hidden">Loading...</span>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="loader small" role="status">
  <span class="visually-hidden">Loading...</span>
</div>
<div class="loader" role="status">
  <span class="visually-hidden">Loading...</span>
</div>
<div class="loader big border-lilac" role="status">
  <span class="visually-hidden">Loading...</span>
</div>
<div class="loader bigger border-lilac" role="status">
  <span class="visually-hidden">Loading...</span>
</div>
`}</code></pre>
    <h2 {...{
      "id": "progress-bar"
    }}>{`Progress Bar`}</h2>
    <p>{`To make the correct animation of the progress bar the ideal is to use some Javascript for progress control. Here we are using an `}<inlineCode parentName="p">{`animate-bar`}</inlineCode>{` class just for demonstration`}</p>
    <div className="progress">
  <div className="progress-bar background-dashed animate-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="progress">
  <div
    class="progress-bar background-dashed animate-bar"
    role="progressbar"
    aria-valuemin="0"
    aria-valuemax="100"
  ></div>
</div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      